import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { assert } from '@/utils/error';
import {
  TransactionInspection,
  TransactionInspectionVariables,
} from './__generated__/TransactionInspection';
import { queryParams } from './queryParams';

const TRANSACTION_QUERY = gql`
  query TransactionInspection($rawId: String!) {
    viewer {
      id
      transactionsV2(rawId: $rawId, orderBy: processedAt, desc: true) {
        edges {
          node {
            rawTransactionId
            uuid
            transactionId
            playerId
            playerSessionId
            playerSessionUUID
            gameId
            gameRoundId
            isRoundFinished
            extTransactionId
            payload
            gameSessionId
            provider
            gameProvider
            brand {
              id
              name
            }
            processedAt
            paymentMethod
            paymentStatus
            transactionType
            openingBalance
            closingBalance
            currency
            comment
            amount
            brand {
              code
            }
            initiator {
              ... on PlayerInitiator {
                player {
                  id
                  firstName
                  lastName
                  email
                }
              }
              ... on AgentInitiator {
                agent {
                  email
                  firstName
                  lastName
                }
              }
            }
            gameDescriptor {
              json
            }
          }
        }
      }
    }
  }
`;

export default function useTransaction() {
  const [query] = useQueryParams(queryParams);

  assert(query.rawTransactionId, 'transaction id missing');

  const [{ data, fetching }, refresh] = useQuery<
    TransactionInspection,
    TransactionInspectionVariables
  >({
    query: TRANSACTION_QUERY,
    variables: {
      rawId: query.rawTransactionId,
    },
  });

  return {
    refresh,
    fetching,
    transaction: data?.viewer.transactionsV2?.edges?.[0]?.node,
  };
}
