import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import React, { FC } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco, nord } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  Value,
} from '@/components';
import { RefreshIcon } from '@/components/icons';
import { useTheme, useTranslate } from '@/contexts';
import formatDate from '@/utils/formatter/formatDate';
import formatMoney from '@/utils/formatter/formatMoney';
import { SanityTransactionInspectionBlockFragment } from './__generated__/SanityTransactionInspectionBlockFragment';
import useTransaction from './useTransaction';

const ZimplerWithdrawalManualApproveButton = loadable(
  () => import('./ZimplerWithdrawalManualApproveButton'),
);

export const Fragment = graphql`
  fragment SanityTransactionInspectionBlockFragment on SanityTransactionInspectionBlock {
    title {
      ...LocaleString
    }
  }
`;

const TransactionInspectionBlock: FC<{
  block: SanityTransactionInspectionBlockFragment;
}> = ({ block }) => {
  const { transaction, fetching, refresh } = useTransaction();
  const { t } = useTranslate();
  const theme = useTheme();

  let code = transaction?.payload || '';
  let lang = '';
  try {
    if (transaction?.payload) {
      lang = 'json';
      code = JSON.stringify(JSON.parse(transaction?.payload), null, 2);
    }
  } catch (e) {}

  const syntaxStyle = theme.name === 'dark' ? nord : docco;

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
            <Value
              fetching={fetching}
              title="Game Provider"
              value={transaction?.gameProvider}
            />
            <Value
              fetching={fetching}
              title="Game Studio"
              value={transaction?.gameDescriptor?.json?.gameProvider?.name}
            />
            <Value
              fetching={fetching}
              title="Processed At"
              value={formatDate(transaction?.processedAt ?? undefined)}
            />
            <Value
              fetching={fetching}
              title="Game Name"
              value={transaction?.gameDescriptor?.json?.name}
            />
            <Value
              fetching={fetching}
              title="Transaction Type"
              value={transaction?.transactionType}
            />
            <Value
              fetching={fetching}
              title="Transaction ID"
              value={transaction?.rawTransactionId}
            />
            <Value
              fetching={fetching}
              title="Ex. Transaction ID"
              value={transaction?.extTransactionId}
            />
            <Value
              fetching={fetching}
              title="Game ID"
              value={transaction?.gameId}
            />
            <Value
              fetching={fetching}
              title="Game Round ID"
              value={transaction?.gameRoundId}
            />
            <Value
              fetching={fetching}
              title="Game Session ID"
              value={transaction?.gameSessionId}
            />
            <Value
              fetching={fetching}
              title="Payment Method"
              value={transaction?.paymentMethod}
            />
            <Value
              fetching={fetching}
              title="Payment Status"
              value={transaction?.paymentStatus}
              suffix={
                transaction && (
                  <div className="mx-1">
                    <ZimplerWithdrawalManualApproveButton
                      transaction={transaction}
                    />
                  </div>
                )
              }
            />
            <Value
              fetching={fetching}
              title="Amount"
              value={formatMoney(transaction?.amount, transaction?.currency)}
            />
            <Value
              fetching={fetching}
              title="Opening Balance"
              value={formatMoney(
                transaction?.openingBalance,
                transaction?.currency,
              )}
            />
            <Value
              fetching={fetching}
              title="Closing Balance"
              value={formatMoney(
                transaction?.closingBalance,
                transaction?.currency,
              )}
            />
            <Value
              fetching={fetching}
              title="Comment"
              value={transaction?.comment}
            />
          </div>

          <SyntaxHighlighter
            language={lang}
            style={syntaxStyle}
            className="col-span-full text-sm"
          >
            {code}
          </SyntaxHighlighter>
        </div>
      </CardBody>
    </Card>
  );
};

export default TransactionInspectionBlock;
